import React from "react"
import PropTypes from "prop-types"

// Styles
import "../styles/components/three-arrowed-steps.scss"

const ThreeSteps = ({ steps }) => {
  return (
    <div className="columns is-gapless three-arrowed-steps-wrapper">
      {steps.map((step, i) => (
        <div key={i} className="column step-wrapper">
          <span className="icon has-margin-right-4 has-text-primary">
            {step.icon}
          </span>
          <span>{step.title}</span>
        </div>
      ))}
    </div>
  )
}
ThreeSteps.propTypes = {
  steps: PropTypes.array.isRequired,
}
export default ThreeSteps

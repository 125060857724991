import React, { useState } from "react"

import { useIntl } from "gatsby-plugin-intl"

import Layout from "../components/layout"
import SEO from "../components/seo"

// Hooks
import useTranslation from "../hooks/useTranslation"

// Icons
import OpenIcon from "../assets/icons/open.svg"
import UserIcon from "../assets/icons/user.svg"
import TwispayIcon from "../assets/icons/t-twispay.svg"
import ShopIcon from "../assets/icons/shop.svg"
import ResponsiveIcon from "../assets/icons/responsive.svg"
import SafeIcon from "../assets/icons/safe-check.svg"
import PercentIcon from "../assets/icons/percent-e.svg"
import SuitcaseIcon from "../assets/icons/suitcase.svg"
import PaintIcon from "../assets/icons/paint.svg"
import BlocksIcon from "../assets/icons/block.svg"
import TruckIcon from "../assets/icons/truck.svg"
import SupplierIcon from "../assets/icons/tower.svg"

// Logos
import ShopifyLogo from "../assets/logos/shopify-logo-big.svg"
import TwispayWalleeLogo from "../assets/twispay-wallee.inline.svg"

// Components
import SectionTitle from "../components/section-title"
import ThreeArrowedSteps from "../components/three-arrowed-steps"
import ThreeLinkedSteps from "../components/three-linked-steps"
import Modal from "../components/modal"
import GetStartedSection from "../components/get-started-section"

// Styles
import "../styles/pages/shopify-payment-service-provider.scss"

// In-page Components
const NumberIcon = ({ number }) => (
  <span className="has-background-primary has-text-white is-flex is-centered-centered number-icon">
    {number}
  </span>
)
const Tooltip = ({ text }) => <p className="is-size-7 tooltip">{text}</p>

const HeroSection = () => {
  const { t } = useTranslation()
  return (
    <section className="section is-hero has-margin-bottom-6">
      <div className="container">
        <div className="columns ">
          <div className="column is-5">
            <h1 className="title main-title has-text-white is-spaced is-2 ">
              {t("Payment Services for Shopify")}
            </h1>
            <h2 className="subtitle main-subtitle has-text-white has-margin-bottom-6">
              {t(
                "Twispay enables new and existing Shopify store owners to quickly, safely, and affordably accept credit card payments through an elegant payment extension explicitly crafted for Shopify online shops"
              )}
            </h2>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://merchant-stage.twispay.com/auth/signup"
              className="button is-success is-small is-rounded"
            >
              {t("Get Started")}
            </a>
          </div>
          <div className="column is-4 is-offset-2">
            <img
              // style={{ margin: "auto" }}
              src={ShopifyLogo}
              alt="Shopify Logo"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

const DetailsSection = () => {
  const { t } = useTranslation()
  const intl = useIntl()
  const isWithComma = intl.locale === "ro" || intl.locale === "it"
  const plans = [
    {
      title: "Starter",
      fee: isWithComma ? "12,95€" : "12.95€",
      transactions: 300,
      extra: isWithComma ? "0,14€" : "0.14€",
    },
    {
      title: "Advanced",
      fee: isWithComma ? "22,95€" : "22.95€",
      transactions: 1000,
      extra: isWithComma ? "0,10€" : "0.10€",
    },
    {
      title: "Business",
      fee: isWithComma ? "59,90€" : "59.90€",
      transactions: 2000,
      extra: isWithComma ? "0,08€" : "0.08€",
    },
  ]
  const [selectedPlan, setSelectedPlan] = useState(plans[0])
  const [modalIsOpen, setModalIsOpen] = useState(false)
  return (
    <section className="section has-margin-bottom-6 details-section">
      <div className="container">
        <SectionTitle
          title={t(
            "A Versatile Payment Integration for Your eCommerce Business"
          )}
          subtitle={t(
            "Streamline and continuously perfect your checkout process with a payment solution that renders beautifully on every device"
          )}
        />
        <div className="columns ">
          <div className="column">
            <div className="columns has-margin-bottom-5">
              {plans.map(plan => (
                <div key={plan.title} className="column has-text-centered">
                  <button
                    onClick={() => setSelectedPlan(plan)}
                    className={`is-size-5  ${
                      selectedPlan.title === plan.title
                        ? "has-text-primary"
                        : "has-text-grey-light"
                    }`}
                  >
                    {plan.title}
                  </button>
                </div>
              ))}
            </div>
            <div className="is-size-5 pricing-wrapper">
              <div className="is-flex is-hspaced">
                <span>{t("Processing rate")}</span>
                <span className="has-text-success">
                  {intl.locale === "ro"
                    ? "1,2"
                    : intl.locale === "it"
                    ? "1,4%"
                    : "1.4%"}
                </span>
              </div>
              <div className="is-flex is-hspaced">
                <span>{t("Monthly gateway fee")}</span>
                <span className="has-text-primary">{selectedPlan.fee}</span>
              </div>
              <div className="is-flex is-hspaced">
                <span>{t("Included transactions")}</span>
                <span className="has-text-primary">
                  {selectedPlan.transactions}
                </span>
              </div>
              <div className="is-flex is-hspaced">
                <span>{t("Cost per extra transaction")}</span>
                <span className="has-text-primary">{selectedPlan.extra}</span>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="logo-wrapper has-margin-bottom-5">
              <TwispayWalleeLogo />
            </div>
            {/* <h5 className="title is-4">{t("100% Swiss Solution")}</h5> */}
            <button
              onClick={() => setModalIsOpen(true)}
              className="button is-rounded is-soft-white"
            >
              {t("Learn more")}
            </button>
            <Modal
              title="Complete Cost Breakdown For Shopify Credit Card Processing"
              onClose={() => setModalIsOpen(false)}
              isOpened={modalIsOpen}
            >
              <p className="has-margin-bottom-5">
                <br /> <br />
                {t(
                  "To enable the solution, you will need both an account with Wallee, for the gateway technical integration to Shopify, and an account with Twispay, for Credit Card payment processing. Total costs presented here are a sum of costs that you need to pay to both service providers."
                )}
              </p>
              <h3 className="title is-4">Wallee</h3>
              <p className="has-margin-bottom-5">
                {t(
                  "Our integration partner prices their services in 3 tiers - Starter, Advanced and Business - each with their distinct costs and benefits. For a complete comparison between these packages, please visit the dedicated pricing page on our partner's website."
                )}
                <br /> <br />
                <strong className="has-text-secondary">
                  {t(
                    "Please note that by signing up via Twispay, you will benefit from a custom deal where a certain number of transactions are included for free with each package:"
                  )}
                </strong>
                <br /> <br />
                <strong className="has-text-secondary">
                  {t("Starter")}: 300{" "}
                </strong>{" "}
                {t("included free transactions")} <br />
                <strong className="has-text-secondary">
                  {t("Advanced")}: 1000
                </strong>{" "}
                {t("included free transactions")} <br />
                <strong className="has-text-secondary">
                  {t("Business")}: 2000
                </strong>{" "}
                {t("included free transactions")}
                <br /> <br />
                {t(
                  "For every transaction over the included set, you will be charged the per-transaction fee according to your selected plan."
                )}
              </p>
              <h3 className="title is-4">Twispay</h3>
              <p className="has-margin-bottom-5">
                {t(
                  "Twispay charges a flat rate of 1.4% of each credit card (Visa or Mastercard) transaction processed through us. There are no monthly or yearly costs, as well as no set-up costs."
                )}
                <br /> <br />
                {t(
                  "Apart from the agreed percentage, Twispay charges a cost starting from 0,10€ per each successful transaction."
                )}
                <br />
                {t(
                  "We do charge an extra 0.5€ for each refund that we process on your behalf, and 15€ for each chargeback."
                )}
                <br /> <br />
                {t(
                  "We make weekly payouts with one week delay from the settlement of funds. The payout fee is free for payouts totalling at least 1000€. Should you require a payout for less than this amount, a 15€ fee per payout will be applied."
                )}
                <br /> <br />
                {t(
                  "Please note that the above fees are limited and subject to our compliance policy. Depending on factors such as traffic source, type of processed credit cards and type of products or services offered, these fees may be subject to alteration on a case by case basis."
                )}
              </p>
            </Modal>
          </div>
        </div>
      </div>
    </section>
  )
}

const SteppedSection = () => {
  const { t } = useTranslation()
  const steps = [
    {
      icon: <NumberIcon number="1" />,
      title: (
        <a
          href="//app-wallee.com/en-us/doc/shopify"
          className="is-flex is-vcentered"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Shopify store")}
          <span className="icon has-text-primary is-small has-margin-left-4">
            <OpenIcon />
          </span>
        </a>
      ),
    },
    {
      icon: <NumberIcon number="2" />,
      title: (
        <a
          href="//twispay.app-wallee.com/pricing/pricing"
          className="is-flex is-vcentered"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Wallee integration")}
          <span className="icon has-text-primary is-small has-margin-left-4">
            <OpenIcon />
          </span>
        </a>
      ),
    },
    {
      icon: <NumberIcon number="3" />,
      title: (
        <a
          href="//app-wallee.com/doc/api/processor/documentation/1537865213929/twispay"
          className="is-flex is-vcentered"
          target="_blank"
          rel="noopener noreferrer"
        >
          {t("Twispay account")}
          <span className="icon has-text-primary is-small has-margin-left-4">
            <OpenIcon />
          </span>
        </a>
      ),
    },
  ]
  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Get Started with Twispay and Wallee")}
          subtitle={t(
            "Want to use Twispay to process payments on your Shopify store? Simply follow the three steps below"
          )}
        />
        <ThreeArrowedSteps steps={steps} />
      </div>
    </section>
  )
}

const ECommerceSection = () => {
  const { t } = useTranslation()
  const steps = [
    {
      icon: <UserIcon />,
      tooltip: t("The customer places an order and pays in her own currency"),
    },
    {
      icon: <TwispayIcon />,
      tooltip: t("We fulfil the transaction and withdraw the customer's funds"),
    },
    {
      icon: <ShopIcon />,
      tooltip: t("You receive your payout in the currency of your choice"),
    },
  ]
  const items = [
    {
      icon: <ResponsiveIcon />,
      title: t("Perfect for Every Device"),
      description: t(
        "Twispay complements your customer flows, matching the look and feel of your brand"
      ),
    },
    {
      icon: <SafeIcon />,
      title: t("Safe & Secure"),
      description: t(
        "Opt for a certified payment service provider that is aware of local customer behaviour"
      ),
    },
    {
      icon: <PercentIcon />,
      title: t("Affordable & Transparent"),
      description: t(
        "Save up on traditional banking fees and hidden costs. Twispay keeps you in the loop"
      ),
    },
  ]

  return (
    <section className="section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Cross-Border eCommerce")}
          subtitle={t(
            "We have built a fully compliant payment solution to provide cross-border ecommerce endeavours with a reliable and secure one-stop-shop for online transactions"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column ">
            <ThreeLinkedSteps steps={steps} color="is-blue" />
          </div>
          <div className="column">
            {items.map((item, i) => (
              <div
                key={item.title}
                className={`is-flex  ${
                  i < items.length - 1 ? "has-margin-bottom-6" : ""
                }`}
              >
                <span className="icon is-medium has-margin-right-4 has-text-primary">
                  {item.icon}
                </span>
                <div>
                  <h4 className="title is-5 ">{item.title}</h4>
                  <p className="subtitle is-6 has-text-secondary">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}

const DropshippingSection = () => {
  const { t } = useTranslation()
  const items = [
    {
      icon: <SuitcaseIcon />,
      title: t("Swift Onboarding"),
      description: t(
        "Once your documents check out, you can start accepting payments in no time"
      ),
    },
    {
      icon: <PaintIcon />,
      title: t("Splendidly Customizable"),
      description: t(
        "The payment page and process melt seamlessly into the user experience"
      ),
    },
    {
      icon: <BlocksIcon />,
      title: t("Powerful Transaction Dashboard"),
      description: t(
        "Staying in charge of your finances is as critical as making sure your website runs properly"
      ),
    },
  ]
  return (
    <section className="section dropshipping-section has-margin-bottom-6">
      <div className="container">
        <SectionTitle
          title={t("Dropshipping")}
          subtitle={t(
            "Backed by our own financial institution, we offer dropshipping merchants flexible financial services at unparalleled rates"
          )}
        />
        <div className="columns is-vcentered">
          <div className="column is-two-fifths">
            {items.map((item, i) => (
              <div
                key={item.title}
                className={`is-flex  ${
                  i < items.length - 1 ? "has-margin-bottom-6" : ""
                }`}
              >
                <span className="icon is-medium has-margin-right-4 has-text-primary">
                  {item.icon}
                </span>
                <div>
                  <h4 className="title is-5 ">{item.title}</h4>
                  <p className="subtitle is-6 has-text-secondary">
                    {item.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
          <div className="column ">
            <div className="steps-wrapper">
              <div className="shipping is-flex is-vcentered">
                <span className="icon has-background-light has-text-secondary">
                  <TruckIcon />
                </span>
              </div>
              <div className="customers is-flex is-vcentered ">
                <span className="icon has-margin-right-5 has-background-light has-text-secondary">
                  <UserIcon />
                </span>
                <Tooltip
                  text={t(
                    "Customer places an order and pays $200 retail price"
                  )}
                />
              </div>
              <div className="shop is-flex is-vcentered">
                <span className="icon has-margin-right-5 has-text-white">
                  <ShopIcon />
                </span>
                <Tooltip
                  text={t("You place the order to your supplier and pay $150")}
                />
              </div>
              <div className="supplier is-flex is-vcentered">
                <span className="icon has-margin-right-5 has-background-light has-text-secondary">
                  <SupplierIcon />
                </span>
                <Tooltip
                  text={t(
                    "Supplier ships the product directly to your customer"
                  )}
                />
              </div>
              <div className="connecting-line has-background-secondary is-horizontal"></div>
              <div className="connecting-line has-background-secondary is-vertical"></div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const ShopifyPage = () => {
  const { t } = useTranslation()
  return (
    <Layout
      stripeClass="is-blue-violet"
      pageName="shopify-payment-service-provider"
    >
      <SEO title={t("Payment Services for Shopify")} />
      <HeroSection />
      <DetailsSection />
      <SteppedSection />
      <ECommerceSection />
      <DropshippingSection />
      <GetStartedSection
        title={t("Don't Settle for JUST Payment Services")}
        subtitle={t("Fine-tune your online payment experience with Twispay")}
      />
    </Layout>
  )
}

export default ShopifyPage

import React from "react"
import PropTypes from "prop-types"
import { motion, AnimatePresence } from "framer-motion"

// Icons
import CloseIcon from "../assets/icons/close.svg"

// Styles
import "../styles/components/modal.scss"

const Modal = ({ children, title, onClose, isOpened }) => {
  return (
    <AnimatePresence>
      {isOpened && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.25 }}
          className="modal-wrapper is-flex is-centered-centered"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h2 className="title has-text-white has-text-centered is-4 is-size-5-mobile">
                {title}
              </h2>
              <button
                onClick={() => onClose()}
                className="icon is-medium has-text-white"
              >
                <CloseIcon />
              </button>
            </div>
            <div className="has-background-white has-text-secondary modal-body">
              {children}
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  )
}
Modal.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}
export default Modal
